//Import css for 360 View
@import '~@photo-sphere-viewer/core/index.css';

/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

.zoom-slider .mat-slider-thumb {
  background-color: theme('colors.neutral.DEFAULT') !important;
  bottom: 0px;
  right: -20px;
  height: 10px;
  width: 40px;
  border: 0px;
  border-radius: 5px;
}

.zoom-slider.mat-slider {
  padding: 0px;
}

/* Tilt Hight Slider */
.tilt-slider .mat-slider-thumb {
  background-color: theme('colors.neutral.DEFAULT') !important;
  bottom: 0px;
  right: -20px;
  height: 40px;
  width: 40px;
  border: 0px;
  border-radius: 5px;
  transform: scale(0) !important;
}

.tilt-slider.mat-slider {
  padding: 0px;
}
.tilt-slider.mat-slider-vertical .mat-slider-wrapper {
  width: 7px;
  top: 8px;
  bottom: 8px;
  left: 23px;
}
.tilt-slider .mat-slider-thumb-label {
  transform: rotate(0deg) !important;
  border-radius: 20% 20% 20% !important;
  bottom: -14x !important;
  right: -20px !important;
}

.tilt-slider.mat-slider-vertical.mat-slider-thumb-label {
  bottom: -14px !important;
  right: -24px !important;
}

.tilt-slider.mat-slider-vertical.mat-slider-thumb-label-text {
  transform: rotate(0deg) !important;
  opacity: 1 !important;
}
.tilt-slider.mat-slider-vertical .mat-slider-track-wrapper {
  height: 100% !important;
  width: 22px !important;
}
.tilt-slider.mat-slider-vertical .mat-slider-track-fill {
  height: 100% !important;
  width: 8px;
}
.tilt-slider.mat-slider-vertical .mat-slider-track-background {
  height: 100% !important;
  width: 8px;
}

.tilt-slider.mat-slider-vertical .mat-slider-thumb-label-text {
  transform: rotate(0deg) !important;
  opacity: 1 !important;
  // color: theme('colors.primary.300') !important;
  font-weight: 700;
}
.tilt-slider.mat-slider-vertical .mat-slider-thumb-label {
  bottom: -14px !important;
  left: -14px !important;
}

.tilt-slider.mat-slider:not(.mat-slider-disabled).cdk-focused
  .mat-slider-thumb-label {
  border-radius: 0%;
}

/** Form styling */
.vapp-select {
  background-color: theme('colors.neutral.600') !important;
  margin-top: 36px;
}

// calendar dropdown style
body.dark {
  .mat-datepicker-content {
    background-color: theme('colors.neutral.600') !important;
  }
  .mat-calendar-body-selected {
    background-color: theme('colors.primary.DEFAULT');
  }
  .mat-datepicker-toggle-active {
    color: theme('colors.primary.DEFAULT');
  }
  .mat-calendar-body-in-range::before {
    background-color: theme('colors.primary.DEFAULT');
  }
  .mat-menu-panel {
    background-color: theme('colors.neutral.600') !important;
  }
}

/* Leaflet Layout Marker */
.layout-marker {
  .minimap {
    .marker.normal {
      background-color: theme('colors.info.DEFAULT');
    }
    .marker.normal.active {
      background-color: theme('colors.primary.DEFAULT');
    }
    .marker-label.normal {
      color: theme('colors.info.DEFAULT');
    }
    .marker-label.normal.active {
      color: theme('colors.primary.DEFAULT');
    }
  }

  /** Marker */
  .marker {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    mask-image: url(/assets/images/markers/layout-marker.svg);
    mask-repeat: no-repeat;
    mask-size: contain;

    .marker-icon {
      position: absolute;
      top: 68%;
      left: 68%;
      width: 8px;
      height: 8px;
      margin-top: -15px;
      margin-left: -8px;
      overflow: hidden;
      border-radius: 50%;
      background-color: theme('colors.white');

      img {
        width: 100%;
        height: auto;
      }
    }

    &.normal {
      background-color: theme('colors.info.DEFAULT');
    }

    &.detection {
      background-color: theme('colors.error.DEFAULT');
    }
  }

  .marker-shadow {
    background: rgba(32, 22, 75, 0.62);
    border-radius: 50%;
    height: 8px;
    width: 8px;
    position: absolute;
    left: 65%;
    // top: 50%;
    margin: 26px 0 0 -7px;
    transform: rotateX(55deg);
    z-index: -2;
    filter: blur(5px);
    -webkit-filter: blur(5px);

    &:after {
      content: '';
      border-radius: 50%;
      height: 40px;
      width: 40px;
      position: absolute;
      margin: -16px 0 0 -13px;
      animation: pulsate 2.5s ease-out;
      animation-iteration-count: infinite;
      opacity: 0;
      background: rgba(32, 22, 75, 0.49);
      animation-delay: 1.1s;
      filter: blur(5px);
      -webkit-filter: blur(5px);
    }
  }

  .marker-label {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    position: absolute;
    text-align: left;
    // top: 10px;
    word-wrap: break-word;
    text-overflow: ellipsis;
    z-index: 10;
    background-color: transparent;
    left: 24px;
    text-shadow: 1px 1px 0 theme('colors.white'),
      -1px -1px 0 theme('colors.white'), 1px -1px 0 theme('colors.white'),
      -1px 1px 0 theme('colors.white');

    &.normal {
      color: theme('colors.info.DEFAULT');
    }

    &.detection {
      color: theme('colors.error.DEFAULT');
    }
  }

  /** Robot Marker */
  .robot-container {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-top: 5px;
    margin-left: 6px;

    &.normal {
      background-color: theme('colors.white');

      &.active {
        background-color: theme('colors.success.900');
      }
    }

    &.detection {
      background-color: theme('colors.white');

      &.active {
        background-color: theme('colors.error.800');
      }
    }

    .robot {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      mask-image: url(/assets/images/markers/robot.svg);
      mask-repeat: no-repeat;
      mask-size: contain;

      .robot-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 25px;
        height: 25px;
        margin-top: -15px;
        margin-left: -12px;
        overflow: hidden;
        border-radius: 50%;

        &.normal {
          background-color: theme('colors.success.700');
        }

        &.detection {
          background-color: theme('colors.error.DEFAULT');
        }

        &.active {
          background-color: theme('colors.white');
        }
      }

      &.normal {
        background-color: theme('colors.success.700');
      }

      &.detection {
        background-color: theme('colors.error.DEFAULT');
      }
    }
  }

  .robot-label {
    width: 150px;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    position: absolute;
    text-align: left;
    top: 10px;
    word-wrap: break-word;
    text-overflow: ellipsis;
    z-index: 10;
    background-color: transparent;
    left: 46px;
    text-shadow: 1px 1px 0 theme('colors.white'),
      -1px -1px 0 theme('colors.white'), 1px -1px 0 theme('colors.white'),
      -1px 1px 0 theme('colors.white');

    &.normal {
      color: theme('colors.success.700');
    }

    &.detection {
      color: theme('colors.error.DEFAULT');
    }

    .robot-detection {
      font-size: 12px;
      font-weight: 500;
      margin-top: -5px;
      display: block;
      color: theme('colors.error.DEFAULT');
    }
  }

  .robot-sonar {
    mask-image: url(/assets/images/markers/robot-sonar.svg);
    mask-repeat: no-repeat;
    mask-size: contain;
    width: 50px;
    height: 50px;
    top: -30px;
    position: absolute;
    cursor: pointer;
    z-index: -1;

    &.normal {
      background-color: theme('colors.success.700');
    }

    &.detection {
      background-color: theme('colors.error.DEFAULT');
    }
  }

  .detection-blinking {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    position: absolute;
    background-color: theme('colors.error.DEFAULT');
    animation: detectionBlinker 1s linear infinite;
    z-index: -10;

    &.robot {
      top: -6px;
      left: -3px;
    }

    @keyframes detectionBlinker {
      0% {
        opacity: 1;
        scale: 0.2;
      }

      100% {
        opacity: 0;
        scale: 1.2;
      }
    }
  }

  .dispatch-blinking {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    position: absolute;
    background-color: theme('colors.primary.DEFAULT');
    animation: dispatchBlinker 0.5s linear infinite;
    z-index: -10;

    &.robot {
      top: -6px;
      left: -3px;
    }

    @keyframes dispatchBlinker {
      0% {
        opacity: 1;
        scale: 0.2;
      }

      100% {
        opacity: 0;
        scale: 1.2;
      }
    }
  }

  /** Event Marker */
  .event {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-image: url(/assets/images/markers/detection-logo-event.svg);
    background-repeat: no-repeat;
    background-size: contain;
  }

  .event-icon {
    position: absolute;
    top: 50%;
    left: 26px;
    width: 10px;
    height: 30px;
    margin-top: -15px;
    margin-left: -8px;
    overflow: hidden;
    background-color: theme('colors.white');
  }

  .event-label {
    width: 150px;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    position: absolute;
    text-align: left;
    top: 7px;
    word-wrap: break-word;
    text-overflow: ellipsis;
    z-index: 10;
    background-color: transparent;
    left: 50px;
    color: theme('colors.error.DEFAULT');
    text-shadow: 1px 1px 0 theme('colors.white'),
      -1px -1px 0 theme('colors.white'), 1px -1px 0 theme('colors.white'),
      -1px 1px 0 theme('colors.white');
  }

  /** Alarm Marker */
  .alarm-marker {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-image: url(/assets/images/markers/alarm-marker.svg);
    background-repeat: no-repeat;
    background-size: contain;
  }

  .alarm-marker-icon {
    position: absolute;
    top: 50%;
    left: 26px;
    width: 10px;
    height: 30px;
    margin-top: -15px;
    margin-left: -8px;
    overflow: hidden;
    background-color: theme('colors.white');
  }

  .alarm-marker-label {
    width: 150px;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    position: absolute;
    text-align: center;
    top: 42px;
    word-wrap: break-word;
    text-overflow: ellipsis;
    z-index: 10;
    background-color: transparent;
    left: 12px;
    color: theme('colors.error.DEFAULT');
    text-shadow: 1px 1px 0 theme('colors.white'),
      -1px -1px 0 theme('colors.white'), 1px -1px 0 theme('colors.white'),
      -1px 1px 0 theme('colors.white');
    transform: translateX(-50%);
  }

  /** Layout sensor marker */
  .layout-sensor {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    mask-image: url(/assets/images/markers/sensors.svg);
    mask-repeat: no-repeat;
    mask-size: contain;
    background-color: theme('colors.primary.DEFAULT');
  }

  .layout-sensor-icon {
    position: absolute;
    top: 6px;
    left: 3px;
    width: 40px;
    height: 40px;
    margin-top: -15px;
    margin-left: -8px;
    overflow: hidden;
    background-color: theme('colors.white');
    border-radius: 50%;
  }

  .layout-sensor-camera {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    mask-image: url(/assets/images/markers/cctv.svg);
    mask-repeat: no-repeat;
    mask-size: contain;
    background-color: theme('colors.primary.DEFAULT');
  }

  .layout-sensor-camera-icon {
    position: absolute;
    top: 6px;
    left: 3px;
    width: 40px;
    height: 40px;
    margin-top: -15px;
    margin-left: -8px;
    overflow: hidden;
    background-color: theme('colors.white');
    border-radius: 50%;
  }

  .layout-sensor-label {
    width: 150px;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    position: absolute;
    text-align: left;
    top: 0px;
    word-wrap: break-word;
    text-overflow: ellipsis;
    z-index: 10;
    background-color: transparent;
    left: 42px;
    color: theme('colors.primary.DEFAULT');
    text-shadow: 1px 1px 0 theme('colors.white'),
      -1px -1px 0 theme('colors.white'), 1px -1px 0 theme('colors.white'),
      -1px 1px 0 theme('colors.white');
  }
  /** Charging station marker */
  .charging.normal {
    background-color: theme('colors.warning.DEFAULT');
  }
  .charging {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    mask-image: url(/assets/images/markers/layout-marker.svg);
    mask-repeat: no-repeat;
    mask-size: contain;

    .charging-icon {
      position: absolute;
      top: 67%;
      left: 56%;
      width: 18px;
      height: 18px;
      margin-top: -15px;
      margin-left: -10px;
      overflow: hidden;
      background-image: url(/assets/images/markers/charge-marker.svg);

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  .charging-label {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    position: absolute;
    text-align: left;
    // top: 10px;
    word-wrap: break-word;
    text-overflow: ellipsis;
    z-index: 10;
    background-color: transparent;
    left: 24px;
    text-shadow: 1px 1px 0 theme('colors.white'),
      -1px -1px 0 theme('colors.white'), 1px -1px 0 theme('colors.white'),
      -1px 1px 0 theme('colors.white');
    &.normal {
      color: theme('colors.info.DEFAULT');
    }
  }

  /** Traffic graph marker */
  .traffic-graph {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: theme('colors.primary.DEFAULT');
    border-radius: 50%;
  }

  .traffic-graph-blinking {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: theme('colors.secondary.DEFAULT');
    opacity: 0.5;
    animation: blinker 1.5s linear infinite;

    @keyframes blinker {
      50% {
        opacity: 0.2;
      }
    }
  }

  .traffic-graph-label {
    width: 150px;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    position: absolute;
    text-align: left;
    top: -3px;
    word-wrap: break-word;
    text-overflow: ellipsis;
    z-index: 10;
    background-color: transparent;
    left: 15px;
    color: theme('colors.info.DEFAULT');
    text-shadow: 1px 1px 0 theme('colors.white'),
      -1px -1px 0 theme('colors.white'), 1px -1px 0 theme('colors.white'),
      -1px 1px 0 theme('colors.white');
  }

  /** Teleop Robot Marker */
  .teleop-robot-container {
    .teleop-robot {
      width: 33px;
      height: 35px;
      position: absolute;
      top: 0;
      left: 0;
      mask-image: url(/assets/images/markers/teleop-robot.svg);
      mask-repeat: no-repeat;
      mask-size: contain;
      background-color: theme('colors.secondary.DEFAULT');
    }

    .teleop-robot-blinking {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      position: absolute;
      top: -8px;
      left: -8px;
      background-color: theme('colors.secondary.DEFAULT');
      opacity: 0.5;
      animation: blinker 1.5s linear infinite;

      @keyframes blinker {
        50% {
          opacity: 0.2;
        }
      }
    }
  }

  &.leaflet-marker-icon {
    transition: opacity 0.1s ease-in-out;
    &.selected {
      opacity: 1 !important;
    }
  }
}

/** Layout Layer Filter */
.layout-layer-filter {
  .mat-checkbox-layout {
    align-items: center;
  }

  .mat-checkbox-layout .mat-checkbox-inner-container .mat-checkbox-frame {
    background-color: theme('colors.neutral.800');
    border-color: theme('colors.neutral.400');
    border-width: 1px;
  }

  &.mat-checkbox-checked {
    .mat-checkbox-layout
      .mat-checkbox-inner-container
      .mat-checkbox-background {
      background-color: theme('colors.primary.DEFAULT');

      .mat-checkbox-checkmark .mat-checkbox-checkmark-path {
        stroke: theme('colors.white') !important;
      }
    }
  }
}

/** Drag routing list */
.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.routing-list.cdk-drop-list-dragging
  .routing-drag-item:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

/** Routing List tooltip */
.mat-tooltip {
  margin-top: 0px!important;
  text-overflow: unset!important;
  word-wrap: break-word!important;

  &.routing-patrol-tooltip {
    overflow: initial;
    background: theme('colors.neutral.400') !important;
    width: 115px;
    height: 32px;
    border-radius: 4px;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: theme('colors.neutral.50');
    text-align: center;
    line-height: 16px;
    margin-top: 0px;
    margin-left: 10px;

    &:after {
      width: 0;
      height: 0;
      content: '';
      position: absolute;
      border-left: 0.5rem solid transparent;
      border-right: 0.5rem solid transparent;
      border-bottom: 0.5rem solid theme('colors.neutral.400');
      top: -0.5rem;
      right: calc(50% - 0.5rem);
      transform: rotate(0deg);
    }
  }

  &.multiple-robot-job-tooltip {
    overflow: initial;
    background: theme('colors.neutral.400') !important;
    width: 88px;
    height: 32px;
    border-radius: 4px;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: theme('colors.neutral.50');
    text-align: center;
    line-height: 16px;
    margin-top: 13px;
    margin-right: -5px;

    &:after {
      width: 0;
      height: 0;
      content: '';
      position: absolute;
      border-left: 0.5rem solid transparent;
      border-right: 0.5rem solid transparent;
      border-bottom: 0.5rem solid theme('colors.neutral.400');
      top: -0.5rem;
      right: calc(50% - 0.5rem);
      transform: rotate(0deg);
    }
  }

  &.multiple-robot-create-job-tooltip {
    overflow: initial;
    background: theme('colors.neutral.400') !important;
    width: 88px;
    height: 32px;
    border-radius: 4px;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: theme('colors.neutral.50');
    text-align: center;
    line-height: 16px;
    margin-top: 13px;
    margin-right: 15px;

    &:after {
      width: 0;
      height: 0;
      content: '';
      position: absolute;
      border-left: 0.5rem solid transparent;
      border-right: 0.5rem solid transparent;
      border-bottom: 0.5rem solid theme('colors.neutral.400');
      top: -0.5rem;
      right: calc(50% - 0.5rem);
      transform: rotate(0deg);
    }
  }
}

.cdk-overlay-pane.call-modal {
  position: relative !important;
}

button {
  &.disable-ripple {
    .mat-ripple {
      display: none;
    }
    .mat-button-focus-overlay {
      display: none;
    }
  }
}

// Background modal dialog
.rm-dialog {
  .mat-dialog-container {
    background: theme('colors.neutral.600') !important;
    border-radius: 4px !important;
  }
}

.selectRobot {
  background-color: theme('colors.neutral.600') !important;
  position: relative;
  top: 65px;
  left: 50%;
}

.global-select {
  background-color: theme('colors.neutral.600') !important;
}

:disabled {
  opacity: 0.7;
}

// button styles
.mat-button.mat-primary,
.mat-stroked-button.mat-primary {
  color: theme('colors.primary.DEFAULT') !important;
}
.mat-button.mat-primary:hover,
.mat-stroked-button.mat-primary:hover {
  background-color: theme('colors.primary.800') !important;
}

.mat-raised-button.mat-primary,
.mat-flat-button.mat-primary,
.mat-fab.mat-primary,
.mat-mini-fab.mat-primary {
  color: #ffffff;
  background-color: theme('colors.primary.DEFAULT') !important;
}

.mat-icon-button.mat-primary {
  color: theme('colors.primary.DEFAULT') !important;
}

.mat-raised-button.mat-button-disabled[disabled],
.mat-flat-button.mat-button-disabled[disabled],
.mat-fab.mat-mat-button-disabled[disabled],
.mat-mini-fab.mat-button-disabled[disabled],
.mat-raised-button.mat-button-disabled[disabled] *,
.mat-flat-button.mat-button-disabled[disabled] *,
.mat-fab.mat-mat-button-disabled[disabled] *,
.mat-mini-fab.mat-button-disabled[disabled] * {
  color: theme('colors.neutral.100') !important;
}

.layout-sensor-popup-container {
  .leaflet-popup-content-wrapper,
  .leaflet-popup-tip {
    background-color: theme('colors.neutral.900') !important;
    color: theme('colors.neutral.100') !important;
  }

  .leaflet-popup-content-wrapper {
    border-radius: 10px !important;
  }
  .leaflet-popup-content {
    margin: 10px 0px 5px 0px !important;
  }
}

// zone area name label
.zone-label {
  background-color: rgba(#333, 0.8) !important;
  max-width: 100px;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #ffffff !important;
  border: none !important;
}

.mat-tab-body.mat-tab-body-active {
  overflow-y: hidden!important;
}
